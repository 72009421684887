import { IconInfoCircle } from '@tabler/icons';

import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { TooltipContentLabel } from 'components/BasicComponents/Tooltips/TooltipContent';
import { Carrier } from 'modules/carrier';
import { Ler } from 'modules/traceabilities';
import { WasteFlow } from 'modules/waste-flow';
import { WasteManager } from 'modules/waste-manager';
import './WasteFlowInfo.scss';

type WasteFlowInfoProps = {
    flow?: WasteFlow | null;
    ler?: Ler;
    manager?: WasteManager;
    carrier?: Carrier | null;
};

const WasteFlowInfo = (props: WasteFlowInfoProps) => {
    return (
        <>
            <div className="WasteFlowInfo" data-testid="WasteFlowInfo">
                {props.flow ? (
                    <>
                        <p data-testid="WasteFlowInfo__valorization">
                            {/* TODO: Unknown valorization percent should change to "null" */}
                            {props.flow?.valuationPercentage === null
                                ? '% de valorización desconocido'
                                : `${props.flow?.valuationPercentage}% de valorización`}
                        </p>

                        <p data-testid="WasteFlowInfo__treatment">
                            Tratamiento {props.flow?.treatment.code}
                            <Tooltip
                                delayShow={500}
                                offset={{ top: -15 }}
                                hideArrow
                                msg={<TooltipContentLabel>{props.flow?.treatment.description}</TooltipContentLabel>}
                            >
                                <IconInfoCircle stroke={2} size={18} />
                            </Tooltip>
                        </p>
                    </>
                ) : (
                    <span>Indica el LER, la gestora y el transportista</span>
                )}
            </div>
        </>
    );
};

export default WasteFlowInfo;
