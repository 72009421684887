import fetcher from 'lib/fetcher';

import { Ler } from '../../domain/Ler';
import { LerApi } from '../../domain/LerApi';

type ApiWasteFlow = {
    ler: {
        code: string;
        type: string;
    };
};

export const httpLerApi: LerApi = {
    async findLerByCriteria(criteria) {
        const { promotionId } = criteria || {};

        /** From PPGCL */
        if (promotionId !== undefined) {
            const params = {
                'promocionId.equals': promotionId,
                size: criteria?.size || 999,
                page: criteria?.page || 0,
                sort: 'procesoGestoraCodLer.codLER.codigoLER,asc',
                ...(criteria?.search !== '' && { 'search.contains': criteria?.search })
            };

            const { data } = await fetcher.get('/api/project-waste-flow', {
                params
            });

            const wasteFlows = data?.content || [];
            const groupedWasteFlowsByLer = Object.groupBy(wasteFlows, (wf: ApiWasteFlow) => wf.ler.code);

            const lers = Object.values(groupedWasteFlowsByLer)
                .filter((group) => group && group.length > 0)
                .map((group) => {
                    const source = (group as ApiWasteFlow[])[0].ler;
                    const ler: Ler = {
                        /** @provisional replace with id */
                        code: source.code,
                        type: source.type
                    };
                    return ler;
                });

            return lers;
        }

        return [];
    }
};
