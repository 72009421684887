import { AgGridReact } from 'ag-grid-react';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { RefObject } from 'react';
import { getLastUpdateDate } from '../helpers/comparative.helpers';
import { useComparativeFiltersTranslation } from './useComparativeFiltersTranslation';

interface UseComparativeExport {
    gridRef: RefObject<AgGridReact>;
}

export function useComparativeExport({ gridRef }: UseComparativeExport) {
    const { format } = useDateFormatter();
    const { getFilterTranslation } = useComparativeFiltersTranslation();

    const getFiltersString = () => {
        const rawFilters = gridRef.current?.api?.getFilterModel();

        if (!rawFilters || Object.keys(rawFilters)?.length === 0) return '';

        const filters = Object.keys(rawFilters).map((key) => getFilterTranslation(rawFilters[key], key));
        const filtersString = 'Filtros aplicados:\n' + filters.join('\n');

        return filtersString;
    };

    const exportAsCsv = () => {
        const currentTime = format(new Date(), 'YYYY/MM/DD_HH:mm');
        const lastUpdate = format(getLastUpdateDate(), 'HH:mm');
        const filtersString = getFiltersString();

        if (!gridRef) return;

        gridRef.current?.api.exportDataAsCsv({
            allColumns: true,
            fileName: 'Comparativa--' + currentTime,
            prependContent: `Fecha y hora de descarga: ${currentTime} (última actualización de datos a las ${lastUpdate}).\n${filtersString}\n`,
            processCellCallback: (params) => {
                const columnId = params.column.getId();

                if (typeof params.value === 'boolean' && params.value) return 'Sí';
                if (typeof params.value === 'boolean' && !params.value) return 'No';

                // UPDATE IF DTO/TABLE CHANGES
                switch (columnId) {
                    case 'promotion.managers':
                    case 'promotion.tags':
                        return params.value?.map((entry) => entry?.name).join(', ');
                    default:
                        return params.value;
                }
            }
        });
    };

    return { exportAsCsv };
}
