import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { Ler, useLerStore } from 'modules/ler';
import './LerSelector.scss';

export type LerSelectorProps = {
    onChange?: (ler: Ler) => void;
    value?: Ler;
    disabled?: boolean;
    filters?: {
        promotionId?: number;
    };
};

const lerToOptionAdapter = (ler) => ({
    value: ler.code,
    label: ler.code + ' - ' + ler.type,
    ler
});

const LerSelector = (props: LerSelectorProps) => {
    const { filters } = props;
    const [t] = useTranslation();
    const [, , { api }] = useLerStore();

    const [loadLerOptions] = useAsyncOptions({
        removePagination: true,
        adapter: lerToOptionAdapter,
        request: ({ search }) =>
            api.findLerByCriteria({
                promotionId: filters?.promotionId,
                search,
                size: 999 // Because we need to group by manager in the frontend
            })
    });

    return (
        <SelectAutocomplete
            // Forces re-render when the filters change to reload the options with the new filters
            dataTestId="ler-selector"
            key={JSON.stringify(filters)}
            name="ler"
            label={t('ler.code')}
            loadOptions={loadLerOptions}
            defaultOptions={props.value === undefined}
            value={props.value ? lerToOptionAdapter(props.value) : undefined}
            onChange={(option) => {
                if (Array.isArray(option)) return;
                props.onChange?.(option?.ler);
            }}
            disabled={props.disabled}
        />
    );
};

export default LerSelector;
