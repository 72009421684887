import { useTranslation } from 'react-i18next';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import KPIHistoricalChart from 'features/KPIs/components/KPIHistoricalChart';
import {
    GoalScopeValue,
    calcKPIStatusFromGoal,
    getCurrentApplicableGoals,
    useValorizationByScope
} from 'modules/promotion-kpis';
import './ValorizationHistory.scss';

interface ValorizationHistoryProps {
    scope: GoalScopeValue;
    promotionId: string;
}

const ValorizationHistory = (props: ValorizationHistoryProps) => {
    const [t] = useTranslation('kpis');
    const valorization = useValorizationByScope({ promotionId: props.promotionId, scope: props.scope });

    const goal = getCurrentApplicableGoals(valorization.data?.goals || [])[0];
    const status = calcKPIStatusFromGoal({ goal });

    return (
        <>
            <ErrorCard error={valorization.error} />

            <div className="ValorizationHistory">
                <KPIHistoricalChart
                    data={valorization.data?.data}
                    goals={valorization.data?.goals.map((goal) => ({ ...goal, type: 'MIN' }))}
                    status={status}
                    unit={t(`valorization.unit`)}
                    yScale={{ type: 'linear', min: 0, max: 105 }}
                />
            </div>
        </>
    );
};

export default ValorizationHistory;
