import { useTranslation } from 'react-i18next';

import cocircularLogo from 'assets/logo-blue.png';
import { TituloH2, TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { cambiarOrdenFecha } from 'utils/helpers/general.helpers';
import './Di.scss';
import { WasteRegisterDi } from 'modules/waste-register/domain/WasteRegisterDi';

interface DiProps {
    referent: any;
    diData: WasteRegisterDi;
}

const Di = ({ referent, diData }: DiProps) => {
    const [t] = useTranslation();

    if (!diData) return null;

    return (
        <div ref={referent} className="di">
            <style>{'@page { margin: 2rem}'}</style>

            <header>
                <img src={cocircularLogo} alt="Logo CoCircular" className="di__logo" />
            </header>
            <div className="di__titleContainer" data-testid="DI__metadata">
                <TituloH2 titulo="Documento de identificación de residuos" />
                <p>
                    {diData.diNum} - {diData.movementDate ? cambiarOrdenFecha(diData.movementDate) : '-'}
                </p>
            </div>

            <hr />
            <div>
                <div>
                    <TituloH3 titulo={t('documentData', { ns: 'wasteRegister' })} />
                    <p>
                        {t('di.number', { ns: 'traceability' })}: <span>{diData.diNum}</span>
                    </p>
                    <p>
                        {t('date.transferStart', { ns: 'common' })}:{' '}
                        <span>{diData.movementDate ? cambiarOrdenFecha(diData.movementDate) : '-'}</span>
                    </p>
                </div>

                <div className="di__container">
                    <div data-testid="DI__transferOperator">
                        <TituloH3 titulo={t('transferOperator', { ns: 'wasteRegister' })} />
                        <p>
                            {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.flow.operator.name}</span>
                        </p>
                        <p>
                            {t('address', { ns: 'common' })}:
                            <span>
                                {diData.flow.operator.address.address} - {diData.flow.operator.address.city.name} -{' '}
                                {diData.flow.operator.address.province.name}
                            </span>
                        </p>

                        <p>
                            CIF:
                            <span>{diData.flow.operator.nif}</span>
                        </p>
                        <p>
                            NIMA:
                            <span>{diData.flow.operator.nima}</span>
                        </p>
                    </div>
                    <div data-testid="DI__owner">
                        <TituloH3 titulo={t('wasteHolderInformation', { ns: 'wasteRegister' })} />
                        <p>
                            {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.owner.name}</span>
                        </p>
                        <p>
                            {t('address', { ns: 'common' })}:
                            <span>
                                {diData.owner.address.address}, {diData.owner.address.city.name},{' '}
                                {diData.owner.address.province.name}
                            </span>
                        </p>
                        <p>
                            CIF:
                            <span>{diData.owner.nif || '-'}</span>
                        </p>
                        <p>
                            NIMA:<span>{diData.owner.nima || '-'}</span>
                        </p>
                    </div>
                </div>
                <div data-testid="DI__destination">
                    <TituloH3 titulo={t('destinationInformation', { ns: 'wasteRegister' })} />
                    <TituloH2 titulo={t('destinationPlantInformation', { ns: 'wasteRegister' })} />
                    <p>
                        {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.flow.manager.name}</span>
                    </p>
                    <p>
                        {t('address', { ns: 'common' })}:
                        <span>
                            {`${diData.flow.manager?.address.address}, ${diData.flow.manager?.address.city.name} (${diData.flow.manager?.address.province.name})`}
                        </span>
                    </p>
                    <p>
                        CIF:<span>{diData.flow.manager.nif}</span>
                    </p>
                    <p>
                        NIMA:<span>{diData.flow.manager.nima}</span>
                    </p>
                    <p>
                        {t('treatmentOperation', { ns: 'wasteRegister' })}:
                        <span>
                            {diData.flow.treatment.code} - {diData.flow.treatment.description}
                        </span>
                    </p>

                    <TituloH2 titulo={t('authorizedWasteInformation', { ns: 'wasteRegister' })} />
                    <p>
                        {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.flow.manager.name}</span>
                    </p>
                    <p>
                        CIF:<span>{diData.flow.manager.nif}</span>
                    </p>
                    <p>
                        NIMA:<span>{diData.flow.manager.nima}</span>
                    </p>
                </div>
                <div className="di__container">
                    <div data-testid="DI__waste">
                        <TituloH3 titulo={t('wasteCharacteristics', { ns: 'common' })} />
                        <p>
                            {t('wasteCode', { ns: 'common' })}:<span>{diData.flow.ler.code}</span>
                        </p>
                        <p>
                            {t('wasteDescription', { ns: 'common' })}:<span>{diData.flow.ler.desc}</span>
                        </p>
                        <p>
                            {t('wasteAmount', { ns: 'common' })} (kg):
                            <span></span>
                        </p>
                    </div>

                    <div data-testid="DI__carrier">
                        <TituloH3 titulo={t('transporterInformation', { ns: 'wasteRegister' })} />
                        <p>
                            {t('nameOrCorporate', { ns: 'common' })}:<span>{diData.flow.carrier?.name || ''}</span>
                        </p>
                        <p>
                            CIF:<span>{diData.flow.carrier?.nif || ''}</span>
                        </p>
                        <p>
                            NIMA:<span>{diData.flow.carrier?.nima || ''}</span>
                        </p>
                    </div>
                </div>
                {diData.observations && (
                    <div data-testid="DI__observations">
                        <TituloH3 titulo={t('comments', { ns: 'common' })} />
                        <p>
                            <span>{diData.observations}</span>
                        </p>
                    </div>
                )}
                <div>
                    <TituloH3 titulo={t('acceptanceRejectionInformation', { ns: 'wasteRegister' })} />
                    <p>
                        {t('date.deliveryDate', { ns: 'common' })}:<span>{cambiarOrdenFecha(diData.movementDate)}</span>
                    </p>
                    <p>
                        {t('amountReceived', { ns: 'wasteRegister' })} (kg):
                        <span></span>
                    </p>
                </div>
            </div>

            <div className="di__singContainer">
                <div className="di__sign">
                    <h5>{t('diRequestedBy', { ns: 'wasteRegister' })}:</h5>
                    <p>
                        {diData.owner.name} {t('theDay', { ns: 'common' })} {cambiarOrdenFecha(diData.movementDate)}
                    </p>
                </div>
                <div className="di__sign">
                    <h5>{t('transportationAcceptedBy', { ns: 'wasteRegister' })}:</h5>
                    <p>{diData.flow.carrier?.name || ''}</p>
                </div>
                <div className="di__sign">
                    <h5>{t('wasteAcceptedBy', { ns: 'wasteRegister' })}:</h5>
                </div>
            </div>
        </div>
    );
};

export default Di;
