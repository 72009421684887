import fetcher from 'lib/fetcher';
import { WasteFlow } from 'modules/waste-flow/domain/WasteFlow';
import { WasteFlowApi } from 'modules/waste-flow/domain/WasteFlowApi';
import { WasteFlowCriteria } from 'modules/waste-flow/domain/WasteFlowCriteria';
import { pgclWasteHandlingAdapter } from './adapters/pgclWasteHandlingAdapter';

export const httpWasteFlowApi: WasteFlowApi = {
    async getProjectWasteFlows(criteria) {
        const mappedCriteria = getApiCriteria(criteria);
        const { data } = await fetcher.get('/api/project-waste-flow', { params: mappedCriteria });

        const results = data?.content
            ?.filter((wf) => (criteria.carrier?.name ? wf.carrier?.name === criteria.carrier.name : true))
            ?.map((wasteFlow: WasteFlow) => {
                /* Convert volume and cost to NULL if they are '0' */
                const adaptedWasteFlow = fixWasteFlowVolumeAndCost(wasteFlow);
                return adaptedWasteFlow;
            });

        return results || [];
    },
    /**
     * Provisional implementation using old endpoint
     */
    async getWasteHandling(criteria) {
        const params = {
            size: 999,
            page: 0,
            'codLERId.equals': criteria.lerId,
            'gestoraId.equals': criteria.wasteManagerId,
            'valorizationCode.notIn': 'O01,T01,N00,Pos00,RU0,A00',
            sort: 'codLER.codigoLER,asc'
        };

        const { data } = await fetcher.get('api/pro-ges-cod-lers', { params });
        const wasteHandlings = data.content.valorizationProcess.map(pgclWasteHandlingAdapter);

        return wasteHandlings;
    },
    async saveWasteHandling(payload) {
        const body = { id: payload.wasteHandlingId };
        // TODO: change "waste-valuation" to "waste-handling"
        const { data } = await fetcher.patch(`api/project-waste-flow/${payload.wasteFlowId}/waste-valuation`, body);
        return data;
    }
};

// TODO: temporary replace 0 for null at volume and cost (future: fix bbdd data and optional fields at ppgcl form) and for valuationPercentage replace 1 for null
export const fixWasteFlowVolumeAndCost = (wasteFlow: WasteFlow) => {
    const wasteFlowCopy: WasteFlow = {
        ...wasteFlow,
        volume: wasteFlow.volume == 0 ? null : wasteFlow.volume,
        valuationPercentage: wasteFlow.valuationPercentage === 1 ? null : wasteFlow.valuationPercentage,
        cost: {
            ton: wasteFlow.cost?.ton == 0 ? null : wasteFlow.cost?.ton,
            unit: wasteFlow.cost?.unit == 0 ? null : wasteFlow.cost?.unit
        }
    };

    return wasteFlowCopy;
};

const getApiCriteria = (criteria: WasteFlowCriteria) => {
    const apiCriteria = {
        ...(criteria?.search !== '' && { 'search.contains': criteria?.search }),
        'promocionId.equals': criteria?.promotionId,
        page: criteria?.page,
        size: criteria?.size,
        sort: criteria?.sort || 'procesoGestoraCodLer.codLER.codigoLER,asc',
        'codLer.contains': criteria?.ler?.code,
        'gestoraName.contains': criteria?.manager?.name

        /** This doesn't work properly!! */
        // 'carrierName.contains': criteria?.carrier?.name
    };

    return apiCriteria;
};
