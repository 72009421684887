import { useHistory } from 'react-router-dom';
import { IconBox } from '@tabler/icons';

import { Traceability, useTraceabilities } from 'modules/traceabilities';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { IconCoins, IconWeight } from 'icons';

import { COLUMNS_ORDER } from './columns/constants/columnsOrder.constants';
import Metadata from './columns/Metadata';
import LerInfo from './columns/LerInfo';
import WasteManagerAndCarrier from './columns/WasteManagerAndCarrier';
import GenericDataField from './GenericDataField';
import ValorizationInfo from './columns/ValorizationInfo';
import DiColumn from './columns/DiColumn';
import ActionsColumn from './columns/ActionsColumn';

interface TraceabilityRowProps {
    traceability?: Traceability;
}

const TraceabilityRow = ({ traceability }: TraceabilityRowProps) => {
    const history = useHistory();
    const [{ promotion }] = usePromotion();
    const [{ removing }] = useTraceabilities();

    if (!traceability) {
        return (
            <tr className={`TraceabilitiesTable--is-skeleton`}>
                {Array.from({ length: 9 }).map((_, index) => (
                    <td key={index}>
                        <div />
                    </td>
                ))}
            </tr>
        );
    }

    const detailUrl = `/promotion/${promotion?.id}/traceabilities/traceability/${traceability.id}`;
    const modifiers = [removing.includes(traceability.id) ? 'TraceabilitiesTable--removing' : ''];

    return (
        <tr
            data-testid="Traceability"
            className={`${modifiers.join(' ')}`}
            role="button"
            onClick={() => history.push(detailUrl)}
        >
            {COLUMNS_ORDER.map(({ column }, index) => {
                switch (column) {
                    case 'metadata':
                        return <Metadata traceability={traceability} key={index} />;
                    case 'lerInfo':
                        return <LerInfo traceability={traceability} key={index} />;
                    case 'wasteManagerAndCarrier':
                        return <WasteManagerAndCarrier traceability={traceability} key={index} />;
                    case 'valorizationInfo':
                        return <ValorizationInfo traceability={traceability} key={index} />;
                    case 'weight':
                        return (
                            <td className="TraceabilitiesTable__measure" key={index}>
                                <GenericDataField
                                    unit="t"
                                    icon={<IconWeight />}
                                    text={traceability.weight.toString()}
                                    testid="Traceability__weight"
                                />
                            </td>
                        );
                    case 'volume':
                        return (
                            <td className="TraceabilitiesTable__measure" key={index}>
                                <GenericDataField
                                    unit="m³"
                                    icon={<IconBox />}
                                    text={traceability.volume !== null ? traceability.volume.toString() : '-'}
                                    testid="Traceability__volume"
                                />
                            </td>
                        );
                    case 'cost':
                        if (!promotion?.permissions.costs) return null;
                        return (
                            <td className="TraceabilitiesTable__measure" key={index}>
                                <GenericDataField
                                    unit="€"
                                    icon={<IconCoins />}
                                    text={traceability.cost !== null ? traceability?.cost?.toString() : '-'}
                                    testid="Traceability__cost"
                                />
                            </td>
                        );
                    case 'di':
                        return <DiColumn traceability={traceability} key={index} />;
                    case 'actions':
                        return <ActionsColumn traceability={traceability} key={index} />;
                    default:
                        return null;
                }
            })}
        </tr>
    );
};

export default TraceabilityRow;
