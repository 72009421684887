import { Trans, useTranslation } from 'react-i18next';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import KPIAlarm from 'features/KPIs/components/KPIAlarm';
import KPIHistoricalChart from 'features/KPIs/components/KPIHistoricalChart';
import { KPIStatus, calcKPIStatusFromGoal, getCurrentApplicableGoals, useMixByScope } from 'modules/promotion-kpis';
import { MixSummary } from 'modules/promotion-kpis/domain/mix/MixSummary';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './MixDetail.scss';

interface MixDetailProps {
    summary: MixSummary;
    promotionId: string;
    promotionProgress: number;
}

const MixDetail = (props: MixDetailProps) => {
    const [t] = useTranslation('kpis');
    const { summary, promotionId } = props;

    const scopeName = 'recoverable';
    const mix = useMixByScope({ promotionId, scope: 'recoverable' });

    const scope = summary?.[scopeName];

    const nodata = scope.value === null || scope.value === undefined;
    const evaluables = scope.goals?.filter((g) => g.progress <= props.promotionProgress);
    const goal = getCurrentApplicableGoals(evaluables)[0] || evaluables[0];
    const status = calcKPIStatusFromGoal({ goal });

    const goalsLink =
        status === KPIStatus.INDETERMINATE || status === KPIStatus.NOT_APPLICABLE
            ? `/promotion/${props.promotionId}/settings/kpi-goals/mix`
            : null;

    const traceabilitiesLink = `/promotion/${props.promotionId}/traceabilities`;

    return (
        <>
            <div className="MixDetail">
                <div>
                    {nodata ? (
                        <KPIAlarm
                            status={calcKPIStatusFromGoal({ goal, data: { value: null } })}
                            title={t(`mix.alarm.nodata.title`, { scope: scope.name })}
                            text={t(`mix.alarm.nodata.subtitle`, { scope: scope.name })}
                            link={{ text: t(`mix.alarm.nodata.link`), to: traceabilitiesLink }}
                        />
                    ) : (
                        <KPIAlarm
                            status={status}
                            title={t(`mix.alarm.${status}.title`, { scope: scope.name })}
                            text={
                                <Trans
                                    ns="kpis"
                                    i18nKey={`mix.alarm.${status}.subtitle`}
                                    values={{
                                        value: currencyFormatDE(scope.value),
                                        goal: goal?.value.value,
                                        promotionProgress: props.promotionProgress,
                                        goalProgress: goal?.progress
                                    }}
                                    components={{ b: <strong /> }}
                                />
                            }
                            link={{
                                text: t(`mix.alarm.${status}.link`),
                                to: goalsLink || traceabilitiesLink
                            }}
                        />
                    )}
                </div>

                <ErrorCard error={mix.error} />

                <div className="KPIs__chart">
                    <KPIHistoricalChart
                        data={mix.data?.data}
                        goals={mix.data?.goals.map((goal) => ({ ...goal, type: 'MAX' }))}
                        status={status}
                        unit={t(`mix.unit`)}
                        yScale={{ type: 'linear', min: 0, max: 105 }}
                    />
                </div>
            </div>
        </>
    );
};

export default MixDetail;
