import { IconAlertCircle } from '@tabler/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import GoBack from 'components/BasicComponents/Buttons/GoBack';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import DocumentPreview from 'components/ComplexComponents/DocumentPreview';
import TraceabilityForm from 'features/traceabilities/components/TraceabilityForm';
import { usePromotion } from 'modules/promotion';
import { useSaveTraceability, useTraceabilityToEdit } from 'modules/traceabilities';
import { TraceabilityForm as ITraceabilityForm } from 'modules/traceabilities/domain/Traceability/TraceabilityForm';
import { getEmptyTraceabilityToEdit } from 'modules/traceabilities/domain/utils/traceabilities.helpers';
import Layout from 'pages/Layout/Layout';
import { notify } from 'utils/helpers/notifications';
import './TraceabilityEditPage.scss';

const TraceabilityEditPage = () => {
    const [t] = useTranslation('traceabilities');
    const history = useHistory();
    const { traceabilityId } = useParams<{ traceabilityId: string }>();
    const [{ promotion }] = usePromotion();
    const { data, loading, error, refetch } = useTraceabilityToEdit({ promotionId: promotion?.id, id: traceabilityId });
    const save = useSaveTraceability();

    const [di, setDi] = useState<{ name: string; bytes: string }>();

    const mode = traceabilityId === undefined ? 'create' : 'edit';
    const traceability = mode === 'edit' ? data : getEmptyTraceabilityToEdit();

    const send = async (form: ITraceabilityForm) => {
        const { loading } = await save.mutate({ ...form, di });

        notify({
            isSuccess: loading === 'succeeded',
            msg: t(`form.save.${loading}`)
        });

        refetch();
    };

    const modifiers = [
        error ? 'TraceabilityEditPage--has-error' : '',
        loading === 'pending' ? 'TraceabilityEditPage--is-loading' : ''
    ];

    return (
        <div className="TraceabilityEditPage__layoutWrapper">
            <Layout>
                <div className={`TraceabilityEditPage ${modifiers.join(' ')}`}>
                    <header className="TraceabilityDetailPage__header">
                        <div>
                            <GoBack goBack={() => history.goBack()} />
                            <TituloH1
                                titulo={mode === 'create' ? 'Nueva trazabilidad' : `${t('edit')} #${traceabilityId}`}
                            />
                        </div>
                    </header>

                    {error && (
                        <AlertCard
                            title={(error as Error)?.message}
                            className="card__alert-error TraceabilityEditPage__errorMsg"
                            icon={<IconAlertCircle />}
                            testId="TraceabilityEditPage__errorMsg"
                        >
                            <code>{JSON.stringify((error as Error).stack, null, 2)}</code>
                        </AlertCard>
                    )}

                    <SimpleCard className="TraceabilityEditPage__main">
                        <DocumentPreview
                            className="TraceabilityEditPage__preview"
                            documentId={traceability?.di?.id}
                            traceabilityId={traceabilityId}
                            onDocumentChange={(doc) => setDi(doc)}
                        />
                        <div className="TraceabilityEditPage__form">
                            <TraceabilityForm
                                key={JSON.stringify(traceability?.id || Math.random())} // Forces form to dismount when traceability changes
                                initialTraceabilityData={traceability || getEmptyTraceabilityToEdit()}
                                onSubmit={send}
                                error={error}
                            >
                                <CreateButton
                                    isLoading={save.loading === 'pending'}
                                    disabled={save.loading === 'pending' || loading === 'pending'}
                                    extraClasses="TraceabilityEditPage__save"
                                    type="submit"
                                    icon={null}
                                    text="Guardar"
                                />
                            </TraceabilityForm>
                        </div>
                    </SimpleCard>
                </div>
            </Layout>
        </div>
    );
};

export default TraceabilityEditPage;
