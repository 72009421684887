import { contextStoreAdapter } from 'modules/shared/infraestructure/adapters/react/contextStoreAdapter';

import { traceabilitiesStore } from '../../application/traceabilitiesStore';

const traceabilitiesContext = contextStoreAdapter(traceabilitiesStore)({ name: 'TraceabilitiesStore' });

export const TraceabilitiesProvider = traceabilitiesContext.Provider;
export const useTraceabilities = traceabilitiesContext.useConsumer;

export const useTraceabilityToEdit = traceabilitiesContext.createQueryHook(({ api }) => api.getTraceabilityToEdit);
export const useSaveTraceability = traceabilitiesContext.createMutationHook(({ api }) => api.saveTraceability);
