import { useDateFormatter } from 'hooks/useDateFormatter';
import { AG_GRID_LOCALE_ES } from 'i18n/agGrid_ES';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/helpers/general.helpers';
import { FilterModelContent } from '../components/ComparativeFilters/ComparativeFilters';

export function useComparativeFiltersTranslation() {
    const [t] = useTranslation('comparative');
    const { format } = useDateFormatter();

    const getFilterTranslation = (filterData: FilterModelContent, key: string) => {
        // multiple filters (conditions) for the same field
        if (filterData.conditions) {
            const operatorTrans = AG_GRID_LOCALE_ES[filterData.operator]; // AND, OR...
            const filtersArray = filterData.conditions.map((filter) => _getIndividualFilter(filter, key));

            const string = capitalize(filtersArray.join(` ${operatorTrans} `));
            return string;
        }

        // else normal, individual filter
        const string = _getIndividualFilter(filterData, key);
        return string;
    };

    const _getIndividualFilter = (filterModel: FilterModelContent, key: string) => {
        const filterType = filterModel.filterType; // text, date...
        const value = filterModel.filter; // selected value by user
        const operationType = filterModel.type; // equals, greaterThan...
        const operationTypeTrans = AG_GRID_LOCALE_ES[operationType]?.toLowerCase();

        // CON LA OPCIÓN "EN BLANCO" O "NO EN BLANCO"
        if (operationType === 'notBlank' || operationType === 'blank') {
            const string = t(key) + ' ' + operationTypeTrans;
            return string;
        }

        // BOOLEAN COLUMNS
        // It doesn't make any sense that AG Grid re-uses filterModel "type" like this for booleans...
        if (operationType === 'true' || operationType === 'false') {
            const string = t(key) + ' ' + operationTypeTrans;
            return string;
        }

        // DATE COLUMNS
        if (filterType === 'date') {
            const formattedDateFrom = format(filterModel.dateFrom, 'DD/MM/YY');
            var dateText = formattedDateFrom;

            // BETWEEN TWO DATES
            if (operationType === 'inRange') {
                const formattedDateTo = format(filterModel.dateTo, 'DD/MM/YY');
                dateText = [formattedDateFrom, formattedDateTo].join(' y ');
            }

            const string = t(key) + ' ' + operationTypeTrans + ' ' + dateText;
            return string;
        }

        // ELSE
        const string = t(key) + ' ' + operationTypeTrans + ' ' + value;
        return string;
    };

    return { getFilterTranslation };
}
