import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import InputText from 'components/BasicComponents/Inputs/InputText';
import WasteFlowCarrierSelector from 'components/ComplexComponents/WasteFlowCarrierSelector';
import { getTraceabilityCost } from 'features/new_edit_traceability/helpers/newEditTracability.helpers';
import { DiExtractionWarningFields } from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion';
import { TraceabilityForm as ITraceabilityForm } from 'modules/traceabilities/domain/Traceability/TraceabilityForm';
import './TraceabilityForm.scss';

export interface TraceabilityFormProps {
    initialTraceabilityData: ITraceabilityForm;
    extraClassNames?: string;
    getInputExtraElement?: (field: DiExtractionWarningFields, value: any) => JSX.Element | null;
    children?: React.ReactNode;
    onSubmit: (traceabilityData: ITraceabilityForm) => void;
    error?: any;
}

const TraceabilityForm = (props: TraceabilityFormProps) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [traceabilityData, setTraceabilityData] = useState(props.initialTraceabilityData);

    useEffect(() => {
        if (!traceabilityData.ppgcl) return;

        const updatedFields = { cost: getUpdatedCost(), volume: traceabilityData.ppgcl?.volume || null };
        setTraceabilityData({ ...traceabilityData, ...updatedFields });
    }, [JSON.stringify(traceabilityData.ppgcl)]);

    useEffect(() => {
        if (!traceabilityData.ppgcl) return;

        const updatedCost = getUpdatedCost();
        setTraceabilityData({ ...traceabilityData, cost: updatedCost });
    }, [JSON.stringify(traceabilityData.weight)]);

    const getUpdatedCost = () => {
        const { ppgcl, weight } = traceabilityData;
        const cost = getTraceabilityCost(weight || 0, ppgcl?.cost?.unit || 0, ppgcl?.cost?.ton || 0);
        return cost;
    };

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        const newValue = (name === 'cost' || name === 'volume') && !value ? null : value;

        setTraceabilityData({ ...traceabilityData, [name]: newValue });
    };

    const handleIncompleteCheckChange = ({ target }) => {
        setTraceabilityData({ ...traceabilityData, incomplete: target.checked });
    };

    if (!promotion) return null;

    return (
        <div className="TraceabilityForm">
            <form
                onSubmit={(e) => {
                    e?.preventDefault();
                    props.onSubmit?.(traceabilityData);
                }}
            >
                <div>
                    <div className="TraceabilityForm__row">
                        <WasteFlowCarrierSelector
                            promotionId={promotion?.id}
                            flow={traceabilityData.ppgcl}
                            onFlowChange={(flow) => setTraceabilityData({ ...traceabilityData, ppgcl: flow || null })}
                        >
                            {props.getInputExtraElement?.('ppgcl', traceabilityData.ppgcl)}
                        </WasteFlowCarrierSelector>

                        <div className="TraceabilityForm__break" />

                        <InputFecha
                            inputContainer="TraceabilityForm__input-date"
                            className="TraceabilityForm__input"
                            label={t('date.date', { ns: 'common' })}
                            name="date"
                            value={traceabilityData.date}
                            values={traceabilityData}
                            setValues={setTraceabilityData}
                            onChange={handleInputChange}
                            validacion={!!props.error}
                            maxDays={0}
                            extraElement={props.getInputExtraElement?.('date', traceabilityData.date)}
                            required
                        />
                        <InputText
                            inputContainer="TraceabilityForm__input-numDi"
                            className="TraceabilityForm__input"
                            label={t('di.number', { ns: 'traceability' })}
                            name="numDi"
                            value={traceabilityData.numDi}
                            onChange={handleInputChange}
                            validacion={!!props.error}
                            extraElement={props.getInputExtraElement?.('numDi', traceabilityData.numDi)}
                        />

                        <div className="TraceabilityForm__break" />

                        <InputNumber
                            className="TraceabilityForm__input TraceabilityForm__input-small"
                            label={t('weight.upperCase', { ns: 'common' }) + ' (T)'}
                            name="weight"
                            id="weight"
                            value={traceabilityData.weight}
                            showThousandSeparator={false}
                            onChange={handleInputChange}
                            validacion={!!props.error}
                            extraElement={props.getInputExtraElement?.('weight', traceabilityData.weight)}
                            required
                            maximumFractionDigits={3}
                            min="1"
                        />

                        {promotion?.permissions.costs && (
                            <div className="TraceabilityForm__field TraceabilityForm__cost">
                                <InputNumber
                                    className="TraceabilityForm__input TraceabilityForm__input-small"
                                    label={t('cost.upperCase', { ns: 'costs' }) + ' (€)'}
                                    name="cost"
                                    value={traceabilityData.cost || ''}
                                    onChange={handleInputChange}
                                    {...({} as any)}
                                />
                                <p>
                                    {!!traceabilityData?.ppgcl?.cost?.unit && (
                                        <p>Unitario: {traceabilityData?.ppgcl?.cost?.unit}€</p>
                                    )}
                                    {!!traceabilityData?.ppgcl?.cost?.ton && (
                                        <p>Tonelada: {traceabilityData?.ppgcl?.cost?.ton}€</p>
                                    )}
                                </p>
                            </div>
                        )}

                        <InputNumber
                            className={`TraceabilityForm__input TraceabilityForm__input-small`}
                            label={t('volume.upperCase', { ns: 'traceability' }) + ' (m³)'}
                            name="volume"
                            value={traceabilityData.volume || ''}
                            onChange={handleInputChange}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />

                        <InputText
                            label={t('detail.vehiclePlate', { ns: 'traceabilities' })}
                            name="vehiclePlate"
                            inputContainer="TraceabilityForm__input-vehiclePlate"
                            className="TraceabilityForm__input"
                            value={traceabilityData.vehiclePlate}
                            onChange={handleInputChange}
                            {...({} as any)}
                        />

                        <div className="TraceabilityForm__break TraceabilityForm__break--always" />

                        <label className="TraceabilityForm__incompleteCheck">
                            <input
                                type="checkbox"
                                checked={traceabilityData.incomplete}
                                onChange={(event) => handleIncompleteCheckChange(event)}
                            />
                            <span>DI incompleto o incorrecto</span>
                        </label>

                        <div className="TraceabilityForm__break TraceabilityForm__break--always" />

                        <InputText
                            label={t('detail.observations', { ns: 'traceabilities' })}
                            name="observations"
                            value={traceabilityData.observations}
                            onChange={handleInputChange}
                            className="TraceabilityForm__input TraceabilityForm__observations"
                            inputContainer="TraceabilityForm__input-fullWidth"
                            {...({} as any)}
                        />
                    </div>
                </div>
                {props.error && (
                    <ErrorCard className="TraceabilityForm__error" error={props.error}>
                        <ul>
                            {props.error.cause.content.errors.map((e) => (
                                <li key={e.field}>{e.error}</li>
                            ))}
                        </ul>
                    </ErrorCard>
                )}
                {props.children}
            </form>
        </div>
    );
};

export default TraceabilityForm;
