import { EditTraceability } from 'modules/traceabilities/infraestructure/api/adapters/traceabilityToEditAdapter';
import { TraceabilityForm } from '../Traceability/TraceabilityForm';

export const getEmptyTraceabilityToEdit = (): EditTraceability => {
    const emptyTraceabilityToEdit: EditTraceability = {
        ...getEmptyTraceabilityForm(),
        di: null
    };

    return emptyTraceabilityToEdit;
};

export const getEmptyTraceabilityForm = (): TraceabilityForm => {
    const emptyTraceability: TraceabilityForm = {
        id: undefined,
        ppgcl: null,
        date: new Date().toISOString().split('T')[0],
        numDi: '',
        weight: null,
        cost: null,
        volume: null,
        vehiclePlate: null,
        incomplete: false,
        observations: ''
    };

    return emptyTraceability;
};
