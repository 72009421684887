import usePrevious from 'hooks/usePrevious';
import { PropsWithChildren, useEffect, useState } from 'react';

import { IconInnerShadowTopRight } from 'icons';
import { Carrier } from 'modules/carrier';
import { Ler } from 'modules/ler';
import { WasteFlow } from 'modules/waste-flow';
import { WasteManager } from 'modules/waste-manager';
import CarrierSelector from './components/CarrierSelector';
import LerSelector from './components/LerSelector';
import WasteFlowInfo from './components/WasteFlowInfo';
import WasteManagerSelector from './components/WasteManagerSelector';
import { useWasteFlowSelector } from './hooks/useWasteFlow';
import './WasteFlowCarrierSelector.scss';

export type WasteFlowCarrierSelectorProps = PropsWithChildren<{
    promotionId: number;

    flow?: WasteFlow | null;
    onFlowChange?: (flow?: WasteFlow | null) => void;
}>;

const WasteFlowCarrierSelector = (props: WasteFlowCarrierSelectorProps) => {
    const { promotionId } = props;
    const isMounted = usePrevious(true);

    const [ler, setLer] = useState<Ler | undefined>(props.flow?.ler);
    const [manager, setManager] = useState<WasteManager | undefined>(props.flow?.manager);
    const [carrier, setCarrier] = useState<Carrier | undefined | null>(props.flow?.carrier);
    const { flow, setFlow, loading } = useWasteFlowSelector({ flow: props.flow, promotionId, ler, manager, carrier });

    // Update the flow when the props change
    useEffect(() => {
        setFlow(props.flow || undefined);
    }, [props.flow?.id]);

    // Update the ler, manager and carrier when the flow changes and fires the onFlowChange event
    useEffect(() => {
        if (!ler) setLer(flow?.ler);
        if (!manager) setManager(flow?.manager);
        if (!carrier) setCarrier(flow?.carrier);
        if (isMounted) props.onFlowChange?.(flow);
    }, [flow?.id]);

    return (
        <div className="WasteFlowCarrierSelector" data-testid="WasteFlowCarrierSelector">
            <div className="WasteFlowCarrierSelector__fields">
                <LerSelector
                    value={ler}
                    onChange={(ler) => {
                        setLer(ler);
                        setManager(undefined);
                        setCarrier(undefined);
                        if (!ler) setFlow(undefined);
                    }}
                    filters={{ promotionId }}
                />

                <WasteManagerSelector
                    value={manager}
                    isClearable={!flow}
                    onChange={(manager) => {
                        setManager(manager);
                        setCarrier(undefined);
                        if (!manager) setFlow(undefined);
                    }}
                    filters={{ promotionId, ler }}
                    disabled={!ler}
                />

                <CarrierSelector
                    value={carrier}
                    isClearable={!flow}
                    onChange={(carrier) => {
                        setCarrier(carrier);
                        if (!carrier) setFlow(undefined);
                    }}
                    filters={{ promotionId, manager, ler }}
                    disabled={!manager || !ler}
                />
                <div className="WasteFlowCarrierSelector__info">
                    <WasteFlowInfo flow={flow} />
                    {!!ler && !!manager && !!carrier && !flow && (
                        <div
                            className={`WasteFlowCarrierSelector__spinner ${loading === 'pending' ? '--animate' : ''}`}
                        >
                            <IconInnerShadowTopRight />
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default WasteFlowCarrierSelector;
