import { useState, useEffect, createRef } from 'react';

const TextArea = ({
    label = '',
    id = '',
    name,
    value,
    onChange,
    className,
    inputContainer,
    validacion,
    required,
    maxLength,
    disabled,
    placeholder,
    errorMsg = '',
    ...rest
}) => {
    const [notComplete, setNotComplete] = useState(false);
    const ref = createRef();

    useEffect(() => {
        inputVacio();
    }, [notComplete, validacion]);

    useEffect(() => {
        if (errorMsg) {
            ref.current.setCustomValidity(errorMsg);
            ref.current.reportValidity();
        } else ref.current.setCustomValidity('');
    }, [errorMsg]);

    const inputVacio = () => {
        if (validacion === true && required && value === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const classInput = () => {
        let inputClass = 'input__input';
        if (notComplete || validacion) {
            inputClass = `${inputClass} input__input--notComplete`;
        }
        if (className) {
            inputClass = `${inputClass} ${className}`;
        }
        if (disabled) {
            inputClass = inputClass + ' input__input--disabled';
        }
        return inputClass;
    };

    return (
        <div className={inputContainer ? inputContainer : ''}>
            <label className="input__label" htmlFor={id}>
                {label} {required ? '*' : ''}
            </label>
            <textarea
                {...rest}
                id={id}
                ref={ref}
                name={name}
                value={value}
                onChange={onChange}
                className={classInput()}
                style={{ height: 'auto' }}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
            />
        </div>
    );
};
export default TextArea;
