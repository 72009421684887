import { TraceabilityForm } from 'modules/traceabilities/domain/Traceability/TraceabilityForm';
import { TraceabilityApiData } from './traceabilityAdapter';

type Sources = {
    data: TraceabilityApiData;
    di: any;
};

export interface EditTraceability extends TraceabilityForm {
    di: { id: number; url: string } | null;
}

export const traceabilityToEditAdapter = ({ data, di }: Sources): EditTraceability => {
    const traceability: EditTraceability = {
        id: data.id,
        date: data.movementDate,
        numDi: data.numDi,
        weight: data.weight,
        cost: data.cost,
        volume: data.volume,
        observations: data.observations,
        incomplete: data.status === 'WARNING',
        vehiclePlate: data.carrier.vehiclePlate,
        ppgcl: {
            id: data.wasteFlowId,
            ler: {
                code: data.lerCode,
                type: data.lerTypeName
            },
            manager: {
                name: data.wasteManager.name
            },
            carrier: {
                name: data.carrier.name
            },
            treatment: {
                code: data.valorizationCode,
                description: data.valorizationDescription
            },
            valuationPercentage: data.valorizationPercent,
            cost: {
                ton: null,
                unit: null
            },
            volume: null
        },
        di: !!data.diUrl
            ? {
                  id: di?.id,
                  url: di?.url
              }
            : null
    };

    return traceability;
};
