import { useLoadTraceabilitiesFromUrl } from 'features/traceabilities/hooks/useLoadTraceabilitiesFromUrl';

import TraceabilitiesFilters from '../TraceabilitiesFilters';
import TraceabilitiesTable from '../TraceabilitiesTable';
import TraceabilitiesListKPIs from './components/TraceabilitiesListKPIs/TraceabilitiesListKPIs';
import TraceabilitiesSummary from './components/TraceabilitiesSummary';

import './TraceabilitiesList.scss';

const TraceabilitiesList = () => {
    useLoadTraceabilitiesFromUrl();

    return (
        <div className="TraceabilitiesList">
            <TraceabilitiesFilters />
            <TraceabilitiesListKPIs />
            <TraceabilitiesSummary limited={true} />
            <TraceabilitiesTable />
        </div>
    );
};

export default TraceabilitiesList;
