import { useTranslation } from 'react-i18next';

import KPIThermometer from 'features/KPIs/components/KPIThermometer';
import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { calcKPIStatusFromGoal, getCurrentApplicableGoals, useValorizationSummary } from 'modules/promotion-kpis';

import ValorizationDetail from './components/ValorizationDetail';

const useValorizationWidget: Widget = (props) => {
    const [t] = useTranslation('kpis');
    const summary = useValorizationSummary(props);

    const recoverable = summary.data?.recoverable;

    const allEvaluableGoals = recoverable?.goals.filter((g) => g.progress <= props.promotionProgress);
    const currentAplicableGoals = getCurrentApplicableGoals(allEvaluableGoals);
    const goal = currentAplicableGoals[0];
    const status = calcKPIStatusFromGoal({ goal, data: { value: recoverable?.value } });

    return {
        status: status,
        Tile: () => {
            return (
                <KPITile
                    label={t('valorization.label')}
                    unit={t('valorization.unit')}
                    value={recoverable?.value}
                    hasError={!!summary.error}
                    status={status}
                >
                    <KPIThermometer
                        maxValue={100}
                        value={recoverable?.value}
                        thresholds={currentAplicableGoals.map((goal) => ({
                            ...goal,
                            type: 'MIN' as const,
                            value: goal.value.value
                        }))}
                        status={status}
                    />
                </KPITile>
            );
        },
        Detail: () => {
            return (
                <ValorizationDetail
                    promotionId={props.promotionId}
                    summary={summary.data}
                    promotionProgress={props.promotionProgress}
                />
            );
        }
    };
};

export default useValorizationWidget;
