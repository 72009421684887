import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconBan, IconCheckbox, IconExternalLink, IconRotateClockwise2 } from '@tabler/icons';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import SaveButton from 'components/BasicComponents/Buttons/SaveButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import { useAutomaticReadingNotifications } from 'features/automatic-reading';
import TraceabilityForm from 'features/traceabilities/components/TraceabilityForm';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useDiExtraction } from 'modules/di-extraction';
import { IApproveDiExtractionData } from 'modules/di-extraction/domain/IApproveDiExtractionData';
import { IDiExtraction } from 'modules/di-extraction/domain/IDiExtraction';
import { usePromotion } from 'modules/promotion';
import { TraceabilityForm as ITraceabilityForm } from 'modules/traceabilities/domain/Traceability/TraceabilityForm';
import './DiExtraction.scss';
import { useDiExtractionWarnings } from './DiExtractionWarnings/useDiExtractionWarnings';

export interface DiExtractionProps {
    diExtraction: IDiExtraction;
}

const DiExtraction = ({ diExtraction }: DiExtractionProps) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();

    const [, analytics] = useAnalyticsStore();
    const notifications = useAutomaticReadingNotifications();
    const [direction, setDirection] = useState<'H' | 'V'>('H');

    const [{ loading, invalid, updating: removing }, { approveDiExtraction, rejectManyDiExtractions }] =
        useDiExtraction();

    const traceability: ITraceabilityForm = {
        ...diExtraction.result,
        id: undefined,
        incomplete: false,
        observations: ''
    };
    const { getWarnings } = useDiExtractionWarnings({ diExtraction });

    const error = invalid[diExtraction.id];
    const isRemoving = removing.includes(diExtraction.id);

    const approveExtraction = async (traceabilityForm: ITraceabilityForm) => {
        const approveDiExtractionData: IApproveDiExtractionData = {
            id: diExtraction.id,
            traceabilityId: null, // TODO: was this something related to E2E?
            ppgclId: traceabilityForm.ppgcl?.id || null,
            date: traceabilityForm.date!,
            numDi: traceabilityForm.numDi,
            weight: traceabilityForm.weight!,
            cost: traceabilityForm.cost,
            volume: traceabilityForm.volume,
            vehiclePlate: traceabilityForm.vehiclePlate,
            incomplete: traceabilityForm.incomplete,
            observations: traceabilityForm.observations
        };

        const createdExtraction = await approveDiExtraction(approveDiExtractionData);

        analytics.event(AnalyticsEvents.AUTOMATIC_READING_DETAIL_APPROVED);

        if (createdExtraction?.traceabilityId) notifications.created(createdExtraction.traceabilityId);
    };

    const rejectExtraction = async () => {
        await rejectManyDiExtractions({ ids: [diExtraction.id], promotionId: promotion?.id });
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_DETAIL_REJECTED);
    };

    const toggleDirection = () => {
        if (direction === 'H') setDirection('V');
        else setDirection('H');
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_PREVIEW_ROTATED);
    };

    const hasPreview = ['pdf', 'jpg', 'png'].includes(diExtraction.mimeType);
    const isImagePreview = ['jpg', 'png'].includes(diExtraction.mimeType);
    const isPdfPreview = diExtraction.mimeType === 'pdf';

    const modifiers = {
        hasPreview: hasPreview ? 'diExtraction--has-preview' : '',
        isRemoving: removing.includes(diExtraction.id) ? 'diExtraction--removing' : '',
        isPending: loading === 'pending' ? 'diExtraction--pending' : '',
        displayDirection:
            (direction === 'V' && 'diExtraction--vertical') || (direction === 'H' && 'diExtraction--horizontal')
    };

    return (
        <div className={`diExtraction ${Object.values(modifiers).join(' ')}`} data-testid="DiExtractionDetail">
            <div className="diExtraction__preview">
                {isPdfPreview && <iframe src={diExtraction.fileUrl} />}
                {isImagePreview && <img src={diExtraction.fileUrl} />}
                {!hasPreview && <p className="diExtraction__previewExtension">{diExtraction.mimeType}</p>}

                <div className="diExtraction__iframeButtons">
                    <SmallButton
                        type="button"
                        titulo={t('rotate', { ns: 'diExtraction' })}
                        icon={<IconRotateClockwise2 />}
                        iconPosition="left"
                        action={() => toggleDirection()}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    {diExtraction.fileUrl && (
                        <SmallButton
                            type="external-link"
                            titulo={t('show.show')}
                            icon={<IconExternalLink />}
                            iconPosition="left"
                            to={diExtraction.fileUrl}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    )}
                </div>
            </div>
            <TraceabilityForm
                initialTraceabilityData={traceability}
                getInputExtraElement={getWarnings}
                onSubmit={(formData) => approveExtraction(formData)}
                error={error}
            >
                <div className="diExtraction__buttons">
                    <BasicButton
                        type="button"
                        color="red"
                        text={t('reject', { ns: 'diExtraction' })}
                        icon={<IconBan stroke={2} size={20} color="white" />}
                        extraClasses="button__small"
                        isLoading={isRemoving}
                        action={() => rejectExtraction()}
                    />
                    <SaveButton
                        type="submit"
                        color="green"
                        text={t('approve', { ns: 'diExtraction' })}
                        icon={<IconCheckbox stroke={2} size={20} color="white" />}
                        extraClasses="button__small"
                        isLoading={isRemoving}
                    />
                </div>
            </TraceabilityForm>
        </div>
    );
};

export default DiExtraction;
