import { Link } from 'react-router-dom';
import '../Buttons.scss';
import { IconInnerShadowTopRight } from 'icons';

const BasicButton = ({
    type = 'link',
    text = 'Basic button',
    color = 'blue',
    icon,
    iconPosition = 'left',
    widthFull = false,
    action = () => {},
    onClick = () => {},
    to = undefined,
    state = {},
    disabled = false,
    extraClasses = '',
    testid = null,
    ghost = false,
    isLoading = false
}) => {
    const getButtonClasses = () => {
        let classes = `buttonAux button__${color}`;
        classes += widthFull ? ' button-full' : '';
        classes += disabled ? ' button__disabled' : '';
        classes += ghost ? ' button--ghost' : '';
        classes += isLoading ? ' button--is-loading' : '';
        classes += extraClasses !== '' ? ' ' + extraClasses : '';
        return classes;
    };

    const ButtonLink = (linkProps) =>
        type === 'external-link' ? (
            <a {...linkProps} href={to} target="_blank" rel="noreferrer">
                {linkProps.children}
            </a>
        ) : (
            <Link {...linkProps}>{linkProps.children}</Link>
        );

    return (
        <>
            {type === 'button' || type === 'submit' ? (
                <button
                    data-testid={testid}
                    type={type}
                    className={getButtonClasses()}
                    onClick={action || onClick}
                    disabled={disabled || isLoading}
                >
                    {iconPosition === 'left' && icon}
                    <span className="button__label">{text}</span>
                    {iconPosition === 'right' && icon}
                    {isLoading && (
                        <div className="button__spinner">
                            <IconInnerShadowTopRight />
                        </div>
                    )}
                </button>
            ) : (
                <ButtonLink className={getButtonClasses()} to={disabled ? '' : { pathname: to, state }}>
                    {iconPosition === 'left' && icon}
                    <span className="button__label">{text}</span>
                    {iconPosition === 'right' && icon}
                </ButtonLink>
            )}
        </>
    );
};
export default BasicButton;
